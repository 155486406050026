import React from "react";
import "../css/haku.scss";

const Terms = () => {
  return (
    <div className="container terms-container" style={{padding: "20px 50px", maxWidth: "1280px", margin: "0 auto"}}>
      <h1>Privacy Policy</h1>
      <p>Mirai Arts LLC and PSS LLC's is an international entertainment company specialized in the mobile environment. We create and publish free mobile games and applications that aim to entertain, inform and connect people by leveraging global creative power.</p>
      
      <p>The purpose of this policy is to provide you with all the important information and explanations about how and why some of your data may be collected and processed by Mirai Arts LLC and PSS LLC's when you use one of our applications or browse on our website. This privacy policy governs and details the main principles that Mirai Arts LLC and PSS LLC's applies to the data we collect and process to:</p>
      
      <p>(i) develop and publish mobile apps (&ldquo;Mirai Arts LLC and PSS LLC's Apps and Games&rdquo;),</p>
      <p>(ii) serve advertising that are tailored to the interests of users (&ldquo;Mirai Arts LLC and PSS LLC's Ads&rdquo;) and</p>
      <p>(iii) run our corporate website.</p>
      
      <h2><strong>Children's Privacy</strong></h2>
      <p>These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13.</p>
      
      {/* Add more content as needed from terms.html */}
    </div>
  );
};

export default Terms; 